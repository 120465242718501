<template>
  <div class="invoice-application-list">
    <!-- 条件搜索部分 -->
    <el-form class="zwx-form" key="msForm" ref="msForm" :model="msForm" label-position="right" @submit.native.prevent>
      <div class="condition-row " style="display: flex">
        <el-form-item label-width="70px" label="行政区划：">
          <el-select class="zwx-select" popper-class="zwx-select-popper"  ref='areaSelect' v-model="msForm.userZoneCode" clearable>
            <el-option v-for="item in agencyAreaList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label-width="70px" label="单位名称：">
          <el-input style="width: 300px !important" class="zwx-input" v-model.trim="msForm.userUnitName" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item label-width="70px" label="订单编号：">
          <el-input style="width: 300px !important" class="zwx-input" v-model.trim="msForm.orderNo" placeholder="请输入" clearable />
        </el-form-item>
      </div>
      <div>
        <el-form-item label-width="70px" label="申请时间：">
          <el-date-picker class="zwx-date-picker" popper-class="zwx-date-picker-popper" v-model="msForm.invoicingTimeBegin" format="yyyy-MM-dd" value-format="yyyy-MM-dd" type="date" :picker-options="$validate.noBigDate($data['msForm'], 'invoicingTimeEnd')" placeholder="开始时间" />
          -
          <el-date-picker class="zwx-date-picker" popper-class="zwx-date-picker-popper" v-model="msForm.invoicingTimeEnd" format="yyyy-MM-dd" value-format="yyyy-MM-dd" type="date" :picker-options="$validate.noSmallDate($data['msForm'], 'invoicingTimeBegin')" placeholder="结束时间" />
        </el-form-item>
        <el-form-item label-width="80px" label="开票状态：">
          <el-checkbox-group class="zwx-checkbox-group" v-model="msForm.stateArr">
            <el-checkbox class="zwx-checkbox" label="1">开具中</el-checkbox>
            <el-checkbox class="zwx-checkbox" label="2">开具完成</el-checkbox>
            <el-checkbox class="zwx-checkbox" label="3">开具失败</el-checkbox>
            <el-checkbox class="zwx-checkbox" label="4">退回</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </div>
    </el-form>
    <el-divider class="base-divider" />
    <div style="padding: 12px 20px;">
      <el-button class="zwx-button zwx-button-icontext-28" type="primary" icon="el-icon-search" @click="search(1)">查询</el-button>
      <el-button v-if="invoicMode==2" class="zwx-button zwx-button-icontext-28" icon="el-icon-check" @click="bathUpdateState(2)">批量开票</el-button>
      <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-download" @click="reportDownload">导出</el-button>
    </div>
    <!-- 列表展示部分 -->
    <el-table class="zwx-table" style="width: 100%" row-key="uuid" :data="dataList" border stripe tooltip-effect="light" @selection-change="listSelectionChange">
      <el-table-column key="selection" type="selection" :reserve-selection="true" width="55" header-align="center" align="center" :selectable="checkBox"></el-table-column>
      <el-table-column prop="userFullName" label="行政区划" min-width="250" header-align="center" align="left"></el-table-column>
      <el-table-column prop="userUnitName" label="单位名称" min-width="250" header-align="center" align="left"></el-table-column>
      <el-table-column prop="userName" label="申请人" min-width="100" header-align="center" align="center"></el-table-column>
      <el-table-column prop="invoicingTime" label="申请日期" width="120" header-align="center" align="center">
        <template slot-scope="{ row }">
          {{ $system.formatDate(row.invoicingTime, 'YYYY-MM-DD') }}
        </template>
      </el-table-column>
      <el-table-column prop="orderNo" label="订单编号" min-width="200" header-align="center" align="center"></el-table-column>
      <el-table-column prop="invoiceType" label="发票类型" width="120" header-align="center" align="center">
        <template slot-scope="scope">
          <span v-if=" scope.row.invoiceType == 32">电子普通发票</span>
          <span v-else>专用发票</span>
        </template>
      </el-table-column>
      <el-table-column prop="state" label="开票状态" width="120" header-align="center" align="center">
        <template slot-scope="scope">
          <span class="zwx-circle-text zwx-circle-text:before" :class="scope.row.state == 1 ? 'zwx-circle-underway zwx-circle-underway:before' : scope.row.state == 2 ? 'zwx-circle-complete zwx-circle-complete::before' : 'zwx-circle-finish zwx-circle-finish::before'">{{ scope.row.state == 1 ? '开具中' : scope.row.state == 2 ? '开具完成' :scope.row.state == 3 ? '开具失败':'退回' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="headType" label="抬头类型" width="90" header-align="center" align="center">
        <template slot-scope="scope">
          <span v-if=" scope.row.headType == 1">企业</span>
          <span v-else>个人</span>
        </template>
      </el-table-column>
      <el-table-column prop="buyerName" label="抬头名称" min-width="250" header-align="center" align="left"></el-table-column>
      <el-table-column prop="buyerCreditCode" label="单位税号" min-width="200" header-align="center" align="center">
        <template slot-scope="scope">
          <span>{{ $zwxSm.sm4JsDecrypt(scope.row.buyerCreditCode)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="buyerBank" label="开户银行" min-width="180" header-align="center" align="left"></el-table-column>
      <el-table-column prop="buyerBankAccount" label="银行账号" width="280" header-align="center" align="center">
        <template slot-scope="scope">
          <span>{{ $zwxSm.sm4JsDecrypt(scope.row.buyerBankAccount)}}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="buyerAddress" label="注册地址" min-width="350" header-align="center" align="left"></el-table-column>
      <el-table-column prop="buyerPhone" label="注册电话" min-width="150" header-align="center" align="center">
         <template slot-scope="scope">
          <span>{{ $zwxSm.sm4JsDecrypt(scope.row.buyerPhone)}}</span>
        </template>
      </el-table-column> -->

      <el-table-column prop="buyerEmail" label="邮箱地址" width="200" header-align="center" align="center">
        <template slot-scope="scope">
          <span>{{ $zwxSm.sm4JsDecrypt(scope.row.buyerEmail)}}</span>
        </template>
      </el-table-column>
      <el-table-column v-if="invoicMode==1" prop="remark" label="备注信息" min-width="400" header-align="left" align="left"></el-table-column>
      <el-table-column v-if="invoicMode==2" prop="remark" label="备注信息" :width="400" header-align="center" align="left"></el-table-column>
      <el-table-column v-if="invoicMode==2" fixed="right" label="操作" min-width="130" header-align="left" align="left">
        <template slot-scope="scope">
          <el-button class="zwx-button zwx-button-text-26" type="text" v-if="scope.row.state == 1" @click="eventCheck(scope.row, 2)">已开票</el-button>
          <el-button class="zwx-button zwx-button-text-26" type="danger" v-if="scope.row.state == 1" @click="eventCheck(scope.row, 4)">驳回</el-button>
          <el-button class="zwx-button zwx-button-text-26" type="text" v-if="scope.row.state == 4" @click="showRemark(scope.row.erroeMsg)">查看驳回原因</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器部分 -->
    <base-pagination class="normal-pagination" :parentPage="msForm.currentPage" :pageSize="pageSize" :total="total" @currentChange="currentChange" />

    <base-dialog class="refuse-form" ref="refusedialog" @determine="refuseFormCommit(4)" @opened="opened()" @close="close()" @cancel="refusedialogCancel" title="驳回原因" style="min-width: 200px !important;width: 30%;">
      <div style="margin-bottom:20px">
        <el-form class="zwx-form" key="msForm" ref="refuseForm" :model="refuseForm" label-position="right" :rules="rules">
          <el-form-item label-width="100px" label="驳回原因：" prop="rmk">
            <el-input class="zwx-textarea" type="textarea" v-model="refuseForm.rmk" maxlength="200" placeholder="请输入驳回原因，上限200字符" style="width: 330px !important;" :autosize="{ minRows: 4}" />
          </el-form-item>
        </el-form>
      </div>
    </base-dialog>

    <!-- 驳回原因 -->
    <el-dialog title="驳回原因" :visible.sync="rmkDialogVisible">
      <div style="margin:10px 20px">
        <span>{{rmk}}</span>
      </div>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: 'InvoiceApplicationList',
  components: {},
  props: {},
  data() {
    return {
      api: this.$store.state.api,
      users: this.$store.state.users,
      config: {},
      //开票方式
      invoicMode: '',
      msForm: {
        userZoneCode: '',
        userUnitName: '',
        stateArr: [],
        orderNo: '',
        currentPage: 1,
        invoicingTimeBegin: '',
        invoicingTimeEnd: '',
      },
      // 选中的发票数组
      selectList: [],
      refuseForm: {
        rmk: '',
      },
      rules: {
        rmk: [{ required: true, message: '请输入驳回原因', trigger: ['change', 'blur'] }],
      },
      dataList: [],
      pageSize: 18,
      total: 0,
      rmkDialogVisible: false,
      rmk: '',
      agencyAreaList: [],//培训机构经营区域集合： 区-街道
    }
  },
  computed: {},
  watch: {},
  created() {
    this.$SimpleCodeTools.getConfig(data => {
      this.config = data
      this.invoicMode = this.config.invoicMode
    })

  },
  mounted() {
    this.queryAgencyBusinessAreaList()
    this.search(this.msForm.currentPage);
  },
  activated() {
    if (this.$route.params.fresh) this.search(1)
  },
  methods: {
    //查询培训机构经营地区
    queryAgencyBusinessAreaList() {
      this.$system.post(
        this.api + '/training/platform/getBusZoneCodeListByAgencyUid-1',
        {},
        true,
        true,
        data => {
          if (data.type === '00') {
            this.agencyAreaList = data.resultList
            this.agencyAreaList.forEach(item => {
              item.value = item.busZoneCode
              item.label = item.busName
            })
          }
        },
        () => {
          this.$system.error({ title: '错误', message: data.mess })
        }
      )
    },

    checkBox(row) {
      // 禁用某些行
      return row.state == 1
    },
    //列表多选
    listSelectionChange(selectList) {
      this.selectList = selectList
    },
    opened() {
    },
    close() {
      this.$refs.refusedialog.show(false)
      this.refuseForm = {
        rmk: ''
      };
    },
    refusedialogCancel() {
      this.close()
    },
    showRemark(erroeMsg) {
      this.rmkDialogVisible = true;
      this.rmk = erroeMsg;
    },
    eventCheck(row, type) {
      this.selectList.push(row)
      if (type == 4) {
        this.$refs.refusedialog.show(true)
        return;
      }
      let uuidList = new Array();
      this.selectList.forEach(item => {
        uuidList.push(item.uuid);
      });
      if (uuidList == null || uuidList.length == 0) {
        this.$system.notify('错误', "请先选择发票", 'error')
        return;
      }
      let data = {
        state: type,
        uuidList: uuidList,
        erroeMsg: type == 4 ? this.refuseForm.rmk : ''
      }
      this.$emit('loading', true)
      this.$system.postJson(
        this.api + '/payment/modifyBathInvoice-1',
        data,
        true,
        true,
        this.eventCheckSuccess,
        this.error
      )
    },
    refuseFormCommit(state) {
      this.$refs.refuseForm.validate(val => {
        if (!val) return
        this.$emit('loading', true)
        let uuidList = new Array();
        this.selectList.forEach(item => {
          uuidList.push(item.uuid);
        });
        if (uuidList == null || uuidList.length == 0) {
          this.$system.notify('错误', "请先选择发票", 'error')
          return;
        }
        let data = {
          state: state,
          uuidList: uuidList,
          erroeMsg: state == 4 ? this.refuseForm.rmk : ''
        }
        this.$system.postJson(
          this.api + '/payment/modifyBathInvoice-1',
          data,
          true,
          true,
          this.eventCheckSuccess,
          this.error
        )
      })
    },
    eventCheckSuccess(data) {
      this.refuseForm = {
        rmk: ''
      };
      this.selectList = [];
      if (data.type === '00') {
        this.$refs.refusedialog.show(false)
        this.$system.notify('成功', data.mess, 'success')
        this.search(1)
      } else if (data.type === '99') {
        this.$router.push({ name: 'MsError', params: { mess: data.mess } })
      } else {
        this.$system.notify('错误', data.mess, 'error')
        this.$emit('loading', false)
      }
    },
    bathUpdateState(state) {
      let uuidList = new Array();
      this.selectList.forEach(item => {
        uuidList.push(item.uuid);
      });
      if (uuidList == null || uuidList.length == 0) {
        this.$system.notify('错误', "请先选择发票", 'error')
        return;
      }
      this.$system.msgbox('', '提示', '您已选择' + uuidList.length + '条数据，确认已开票？', '确定', '取消', () => {
        if (state == 4) {
          this.$refs.refusedialog.show(true)
          return;
        }
        this.$emit('loading', true)
        let data = {
          state: state,
          uuidList: uuidList,
          erroeMsg: state == 4 ? this.refuseForm.rmk : ''
        }
        this.$system.postJson(
          this.api + '/payment/modifyBathInvoice-1',
          data,
          true,
          true,
          this.eventCheckSuccess,
          this.error
        )
      })
    },

    currentChange(currentPage) {
      this.search(currentPage)
    },
    search(currentPage) {
      this.$emit('loading', true)
      this.msForm.currentPage = currentPage
      // if (typeof (this.msForm.userZoneCode) != 'string') {
      //   if (this.msForm.userZoneCode.length == 1) {
      //     this.msForm.userZoneCode = this.msForm.userZoneCode[0];
      //   } else if (this.msForm.userZoneCode.length == 2) {
      //     this.msForm.userZoneCode = this.msForm.userZoneCode[1];
      //   } else if (this.msForm.userZoneCode.length == 0) {
      //     this.msForm.userZoneCode = '';
      //   }
      // }
      let data = { ...this.msForm }
      this.$system.postJson(this.api + '/payment/getPxjgInvoiceList-1', data, true, true, this.searchSuccess, this.error)
    },
    searchSuccess(data) {
      if (data.type === '00') {
        this.dataList = data.resultList
        this.total = data.resultCount
        this.pageSize = data.pageSize
      } else if (data.type === '99') {
        this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
      } else {
        this.$system.notify('错误', data.mess, 'error')
      }
      this.$emit('loading', false)
    },
    error() {
      this.$system.notify('错误', '网络连接失败', 'error')
      this.$emit('loading', false)
    },
    reportDownload(){
      let data = { ...this.msForm }
      delete data.currentPage
      this.$FormatData.exportExcel(this.api + '/payment/exportPxjgInvoiceList-1', data)
    }
  }

}
</script>

<style scoped lang="less">
.invoice-application-list {
  /deep/ .el-dialog {
    min-width: 500px !important;
    width: 500px !important;
  }
  /deep/.el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #4a75f5 !important;
    border-color: #4a75f5 !important;
  }
}
</style>
