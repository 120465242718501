var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "detection-report-basic-info-edit" },
    [
      _c("zwx-step", { attrs: { index: 2, dataList: _vm.dataList } }),
      _c("el-divider", { staticClass: "base-divider" }),
      _c(
        "el-form",
        {
          key: "addForm",
          ref: "addForm",
          staticClass: "zwx-form edit-form",
          attrs: {
            model: _vm.addForm,
            rules: _vm.rules,
            "label-position": "right"
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "edit-row" },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "110px",
                    label: "环境条件：",
                    prop: "enviromentCondition"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-textarea",
                    staticStyle: { width: "804px !important" },
                    attrs: {
                      type: "textarea",
                      autosize: { minRows: 4 },
                      maxlength: "2000",
                      placeholder: "正文内容",
                      clearable: ""
                    },
                    model: {
                      value: _vm.addForm.enviromentCondition,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.addForm,
                          "enviromentCondition",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "addForm.enviromentCondition"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "edit-row" },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "110px",
                    label: "检测结论：",
                    prop: "detectionConclusion"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-textarea",
                    staticStyle: { width: "804px !important" },
                    attrs: {
                      type: "textarea",
                      autosize: { minRows: 4 },
                      maxlength: "2000",
                      placeholder: "正文内容",
                      clearable: ""
                    },
                    model: {
                      value: _vm.addForm.detectionConclusion,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.addForm,
                          "detectionConclusion",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "addForm.detectionConclusion"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "edit-row" },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "110px",
                    label: "建议：",
                    prop: "reportSuggest"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-textarea",
                    staticStyle: { width: "804px !important" },
                    attrs: {
                      type: "textarea",
                      autosize: { minRows: 4 },
                      maxlength: "2000",
                      placeholder: "正文内容",
                      clearable: ""
                    },
                    model: {
                      value: _vm.addForm.reportSuggest,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.addForm,
                          "reportSuggest",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "addForm.reportSuggest"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "fixed-footer",
        [
          _c(
            "el-button",
            {
              staticClass: "zwx-button zwx-button-32",
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.save(1)
                }
              }
            },
            [_vm._v("提交")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }