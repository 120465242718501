<template>
  <div class="detection-report-basic-info-edit">
    <zwx-step :index="2" :dataList="dataList"></zwx-step>
    <el-divider class="base-divider" />
    <el-form class="zwx-form edit-form" ref="addForm" key="addForm" :model="addForm" :rules="rules" label-position="right" @submit.native.prevent>
      <div class="edit-row">
        <el-form-item label-width="110px" label="环境条件：" prop="enviromentCondition">
          <el-input class="zwx-textarea" type="textarea" v-model.trim="addForm.enviromentCondition" style="width: 804px !important;" :autosize="{ minRows: 4 }" maxlength="2000" placeholder="正文内容" clearable />
        </el-form-item>
      </div>
      <div class="edit-row">
        <el-form-item label-width="110px" label="检测结论：" prop="detectionConclusion">
          <el-input class="zwx-textarea" type="textarea" v-model.trim="addForm.detectionConclusion" style="width: 804px !important;" :autosize="{ minRows: 4 }" maxlength="2000" placeholder="正文内容" clearable />
        </el-form-item>
      </div>
      <div class="edit-row">
        <el-form-item label-width="110px" label="建议：" prop="reportSuggest">
          <el-input class="zwx-textarea" type="textarea" v-model.trim="addForm.reportSuggest" style="width: 804px !important;" :autosize="{ minRows: 4 }" maxlength="2000" placeholder="正文内容" clearable />
        </el-form-item>
      </div>
    </el-form>
    <fixed-footer>
      <el-button class="zwx-button zwx-button-32" type="primary" @click="save(1)">提交</el-button>
    </fixed-footer>
  </div>
</template>

<script>
import ZwxStep from '@/views/agency/workplace_detection/components/ZwxStep'
export default {
  name: 'DetectionReportConclusion',
  components: { ZwxStep },
  props: {
    rid: { type: String, default: '' },
  },
  data() {
    return {
      dataList: [
        { index: 0, text: '基本信息' },
        { index: 1, text: '检测结果' },
        { index: 2, text: '检测结论及建议' },
      ],
      users: this.$store.state.users,
      api: this.$store.state.api,
      employerUuid: undefined,
      addForm: {
        rid: this.$route.params.rid,
        enviromentCondition: '',
        detectionConclusion: '',
        reportSuggest: '',
        dataState: 1,
        fillingStep: 3,
      },
      rules: {
        enviromentCondition: [{ required: true, message: '请输入环境条件', trigger: ['change', 'blur'] }],
        detectionConclusion: [{ required: true, message: '请输入检测结论', trigger: ['change', 'blur'] }],
        reportSuggest: [{ required: true, message: '请输入建议', trigger: ['change', 'blur'] }],
      },
    }
  },
  computed: {
    breadcrumbs() {
      return this.$store.state.breadcrumbs
    },
  },
  created() {},
  mounted() {
    if (this.$zwxBase.verifyIsNotBlank(this.$route.params.employerUuid)) {
      this.employerUuid = this.$route.params.employerUuid
    }
    if (!this.$zwxBase.verifyIsBlank(this.$route.params.rid)) {
      this.queryDetectionReportBasicInfo(this.$route.params.rid)
    }
  },
  methods: {
    queryDetectionReportBasicInfo(rid) {
      let data = {
        rid: rid,
        employerUuid: this.employerUuid,
      }
      this.$emit('loading', true)
      this.$system.get(
        this.api + '/oh/workplace/detection/getTdDetectionReport-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type === '00') {
            this.addForm.rid = data.detectionReportDetail.rid
            if (data.detectionReportDetail.enviromentCondition) {
              this.addForm.enviromentCondition = data.detectionReportDetail.enviromentCondition
            }
            if (data.detectionReportDetail.detectionConclusion) {
              this.addForm.enviromentCondition = data.detectionReportDetail.detectionConclusion
            }
            if (data.detectionReportDetail.reportSuggest) {
              this.addForm.enviromentCondition = data.detectionReportDetail.reportSuggest
            }
          } else if (data.type === '99') {
            this.$router.push({ name: 'MsError', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    clickError() {},
    save(operateType) {
      this.$refs['addForm'].validate(valid => {
        if (valid) {
          this.submit(operateType)
        }
      })
    },
    submit(operateType) {
      let data = {
        rid: this.addForm.rid,
        ...this.addForm,
        employerUuid: this.employerUuid,
      }
      this.$emit('loading', true)
      this.$system.postJson(
        this.api + '/oh/workplace/detection/addOrModifyTdDetectionReport-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type === '00') {
            this.$system.notify('成功', '提交成功', 'success')
            this.addForm.rid = data.rid
            if (operateType == 1) {
              this.$router.push({ name: 'DetectionReportManagement', params: { rid: this.addForm.rid } })
            }
          } else if (data.type === '99') {
            this.$router.push({ name: 'MsError', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },

    back() {
      this.$router.replace({ name: this.breadcrumbs[this.breadcrumbs.length - 2].name })
    },
  },
}
</script>

<style lang="less" scoped>
/deep/.detection-report-dialog .el-dialog {
  width: 680px !important;
  min-width: 680px !important;
}
.upload-tip {
  margin: 9px 0 0 9px;
  height: 12px;
  font-size: 12px;
  font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Regular;
  font-weight: 400;
  text-align: justify;
  color: #595959;
  line-height: 12px;
}
</style>
<style lang="less">
.file-name-hidden {
  display: inline-block;
  max-width: 320px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
